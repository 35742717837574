<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('roles.rolesActions') }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="(roleId && !!model.is_locked && model.user_id!==user.id)">
      <v-col cols="12" class="my-3">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1 error--text" >*{{ $t('common.documentReadOnlyModeNotice',{userId:model.user.name}) }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <v-card-title class="py-1">
            <h5 style="max-width: 220px;" :class="roleId ? 'px-1 text-truncate font-italic primary--text font-weight-bold' : 'primary--text text--darken-1'" class="font-weight-regular">{{ roleId ? originModel.name && `${roleId}. ${originModel.name}`  : $t('roles.addRole') }}</h5>
            <v-spacer />
            <v-menu bottom v-if="roleId" :close-on-click="true" :offset-y="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class=" ma-2" small outlined v-bind="attrs" v-on="on" :disabled="!valid || (!!model.is_locked && model.user_id!==user.id)">
                  <v-icon left>
                    mdi-menu-down
                  </v-icon>
                  {{$t('quotation.options')}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item dense class="primary--text" @click="actDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-pulse
                      </v-icon>
                      {{$t('quotation.activityLog')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense v-if="accessRight.includes('delete')" class="error--text" @click="confirmationDialog = true, delete_item = roleId">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="error--text">
                        mdi-delete-sweep-outline
                      </v-icon>
                      {{$t('common.delete')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="!valid || (!!model.is_locked && model.user_id!==user.id) || (accessRight.length < 2 && accessRight.includes('show'))"
              @click="saveRole"
            >
              {{ $t('roles.save') }}
            </v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="text-h5">
            <RoleFormSkeleton v-if="roleId && Object.keys(roleById).length <= 0"/>
            <v-form
              v-else
              ref="form"
              v-model="valid"
              lazy-validation
              :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
            >
              <v-row>
                <v-col cols="4" :dense="true">
                  <v-text-field
                    class="asterisk"
                    hide-details="auto"
                    v-model.trim="model.name"
                    :label="$t('roles.roleName')"
                    :rules="roleValid"
                    required
                  />
                </v-col>
                <v-col cols="4" :dense="true">
                  <v-text-field
                    hide-details="auto"
                    v-model.trim="model.name_he"
                    :label="$t('roles.roleHeName')"
                    :rules="roleHebrewValid"
                    required
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    hide-details="auto"
                    v-model="model.description"
                    :label="$t('roles.description')"
                    :rules="[lessThanValidation($t('roles.description'), $t('common.less'), 255, model.description)]"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-subtitle class="px-0 py-2">
                    <h3 class="primary--text font-weight-regular">{{$t('roles.rolePermissions')}}</h3>
                  </v-card-subtitle>
                  <v-divider class="mb-2 primary" />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card
                    class="mx-auto"
                    outlined
                  >
                    <v-card-text class="pa-0" v-for="(item, index) in permissionModel" :key="item.id">
                      <v-card-title v-if="index === 0" class="py-1">
                        <h5 class="primary--text text--darken-1 font-weight-regular">{{$t('roles.'+permissionModel[index].title)}}</h5>
                        <v-spacer />
                      </v-card-title>
                      <v-divider class="pb-1" v-if="index === 0"/>
                      <v-checkbox
                        :dense="true"
                        class="mx-2 mt-0"
                        v-model="permission_ids"
                        :label="$t('roles.'+permissionModel[index].slug)"
                        :value="permissionModel[index].id"
                      ></v-checkbox>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="2"></v-col>
                <v-col cols="12" sm="6">
                  <div class="d-flex">
                    <v-card
                      class="overflow-y-auto d-inline"
                      width="325"
                      max-height="323px"
                    >
                      <ListSkeleton v-if="personalMenu.length <= 0" type="list"/>
                      <v-treeview
                        v-else
                        class="text-subtitle-1"
                        dense
                        selectable
                        selected-color="primary"
                        :items="personalMenu"
                        return-object
                        item-text="menu"
                        item-key="slug"
                        v-model="selectableMenu"
                      >
                      </v-treeview>
                    </v-card>
                    <v-card
                      class="mx-16 overflow-y-auto overflow-x-hidden d-inline"
                      width="325"
                      max-height="323px"
                    >
                      <v-list dense>
                        <v-list-item
                          v-for="(selectMenu, index) in menuChange"
                          :key="index"
                          class="text-start body-2"
                        >
                        {{ $t(`route.${selectMenu.slug}`) }}
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <ActivityLogTableDialog :actDialog.sync="actDialog" :docId.sync="roleId" docType="Role"/>
      <confirmation-dialog :dialog.sync="confirmationDialog" :delete_item.sync="delete_item" @deleteItem="deleteItem"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import RoleFormSkeleton from '@/components/skeleton/RoleFormSkeleton';
import {changedFormData} from '@/utils';
import ActivityLogTableDialog from '@/components/activityLog/Dialogs/ActivityLogTableDialog.vue';
import { routes } from '@/router/company.js';
import ConfirmationDialog from '@/components/ConfirmationDialog';

export default {
  name: 'RolesAction',
  components: {
    RoleFormSkeleton,
    ActivityLogTableDialog,
    ConfirmationDialog
  },
  data() {
    return {
      loading: false,
      valid: false,
      model: {
        role_list: []
      },
      originModel: {},
      permission_ids: [],
      newRole: {},
      actDialog: false,
      menu: [],
      confirmationDialog: false,
      delete_item: null,
    }
  },
  computed: {
    roleValid() {
      return [
        v => !!v || this.$t('formRules.roleRequired'),
        v => !/^\s*$/.test(v) || this.$t('formRules.roleRequired'),
        v => !v || (v && v.length <= 255) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('roles.roleName'), type: this.$t('common.less'), size: 255 }),
      ];
    },
    roleHebrewValid() {
      return [
        v => !v || !/^\s*$/.test(v) || this.$t('formRules.roleRequired'),
        v => !v || (v && v.length <= 255) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('roles.roleHeName'), type: this.$t('common.less'), size: 255 }),
      ];
    },
    roleId() {
      return this.$route.params.id
    },
    ...mapGetters([
      'accessRight'
    ]),
    ...mapGetters({
      roleById: 'roleById',
      permissionModel: 'allPermissions',
      roleQuery: 'roleQuery',
      user: 'user',
      moreThanOneUserLogout: 'moreThanOneUserLogout'
    }),
    personalMenu() {
      let dataMenu = []
      for (let index = 0; index < routes.length; index++) {
        const route = routes[index];
        let data = {}
        if(route.path === '/') {
          if (route.meta.title) {
            data = Object.assign(
              {
                slug: route.meta.title.split('.')[1],
                menu: this.$t(route.meta.title),
              }
            )
            if (route.children) {
              let childData = this.makeDynamicMenu(route.children)
              if(childData.length > 0) {
                data = Object.assign({...data, children: childData})
              }
            }
          } else {
            data = Object.assign(
              {
                slug: route.children[0].meta.title.split('.')[1],
                menu: this.$t(route.children[0].meta.title),
              }
            )
          }
          dataMenu.push(data)
        }
      }
      return dataMenu.filter((item) => item.slug !== 'dashboard');
    },
    selectableMenu: {
      get() {
        return this.menu;
      },
      set(value) {
        this.menu = value;
      },
    },
    menuChange() {
      return this.menuUpdate(this.menu);
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.$store.dispatch('GetRoleById', this.$route.params.id).then((res) => {
        if (res.role_list) {
          const splitWords = res.role_list.split(',')
          this.menu = this.findMenu(splitWords, this.personalMenu);
        }
      })
      if (!this.model.is_locked && this.$route.params.id) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Role',id:this.$route.params.id,is_locked:true});
      }
    }
    await this.$store.dispatch('GetAllPermissions');
  },
  watch: {
    roleById() {
      if (Object.keys(this.roleById).length > 0) {
        Object.assign(this.model, this.roleById);
        if(this.roleById.permissions.length > 0) {
          this.roleById.permissions?.forEach(item => {
            this.permission_ids.push(item.id);
          });
        }
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Role',id:this.model.id,is_locked:false});
        this.$store.dispatch('LogOut');
        this.$router.push({ name: 'login' });
      }
    }
  },
  async created() {
    window.addEventListener('beforeunload', this.handler)
  },
  beforeDestroy() {
    if (this.model.id && !this.moreThanOneUserLogout && this.model.is_locked && this.model.user_id == this.user.id || this.model.id && !this.model.user_id && !this.moreThanOneUserLogout) {
      this.$store.dispatch('PostDocumentOpenAction',{table:'Role',id:this.model.id,is_locked:false});
    }
    this.$refs.form ? this.$refs.form.reset() : '';
    this.model = {},
    this.$store.commit('SET_ROLE_BY_ID', {});
    this.$store.commit('SET_USER_LOGOUT', false)
    window.removeEventListener('beforeunload', this.handler)
  },
  methods: {
    async handler(event) {
      if (this.model.id && !this.moreThanOneUserLogout && this.model.is_locked && this.model.user_id == this.user.id || this.model.id && !this.model.user_id && !this.moreThanOneUserLogout) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Role',id:this.model.id,is_locked:false});
      }
      event.preventDefault();
    },
    callBackFunction() {
      this.$router.push({
        name: "Roles",
        query: {
          ...this.roleQuery,
        },
      });
    },
    findMenu(slugArray, menuItems) {
      const result = [];
      function findMenuItem(slug, items) {
        for (const item of items) {
          if (item.slug === slug) {
            result.push({ slug: item.slug, menu: item.menu });
            if (item.children) {
              this.findMenu(item.children.map(child => child.slug), menuItems);
            }
            return;
          }
          if (item.children) {
            findMenuItem(slug, item.children);
          }
        }
      }
      for (const slug of slugArray) {
        findMenuItem(slug, menuItems);
      }
      return result;
    },
    menuUpdate(arr) {
      return arr?.map((x) => {
        return {
          slug: x.slug,
          menu: x.menu,
        };
      });
    },
    makeDynamicMenu(childrens) {
      let data = []
      for (let index = 0; index < childrens.length; index++) {
        const child = childrens[index];
        if (!child.hidden) {
          let dataChild = {}
          dataChild = Object.assign(
            {
              slug: child.meta.title.split('.')[1],
              menu: this.$t(child.meta.title),
            }
          )
          if (child.children) {
            let childData = this.makeDynamicMenu(child.children)
            if (childData.length > 0) {
              dataChild = Object.assign({...dataChild, children: childData})
            }
          }
          data.push(dataChild)
        }
      }
      return data;
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return !v || (v && v.length <= sizeValue) || this.$t('formRules.lengthValidMessage', { fieldName: fieldValue, type: typeValue, size: sizeValue });
    },
    async saveRole() {
      if(this.$refs.form.validate()){
        this.loading = true;
        let data;
        if(this.$route.params.id) {
          let oldPermission = [];
          this.model.permissions?.forEach(item => {
            oldPermission.push(item.id);
          });
          let addNewPermissions = this.permission_ids.filter(x => oldPermission.indexOf(x) === -1);
          let deletePermissions = oldPermission.filter(x => this.permission_ids.indexOf(x) === -1);
          this.model.role_list = this.menu.map(item => item.slug)
          // save only update model value using changedFormData from utils
          let saveData = changedFormData(this.model, this.originModel);
          data = await this.$store.dispatch('EditRoles', {role_id: this.$route.params.id, role: saveData, addNewPermissions: addNewPermissions, deletePermissions: deletePermissions}).catch((e) => {
            this.loading = false;
            console.log(e)
          });
          if(data) {
            this.$refs.form.reset();
            this.loading = false;
            await this.$store.dispatch('GetRoleById', data.data.id);
            await this.$store.dispatch('GetAllPermissions');
          }
        } else {
          this.model.role_list = this.menu.map(item => item.slug)
          data = await this.$store.dispatch('SetRoles', {role: this.model, permission_ids: this.permission_ids}).catch((e) => {
            this.loading = false;
            console.log(e)
          });
          if(data) {
            this.$refs.form.reset();
            this.loading = false;
            if (this.accessRight.includes('edit')) {
              await this.$router.push('/role/editRole/' + data.data.id).catch(()=>{});
              await this.$store.dispatch('GetRoleById', data.data.id);
              await this.$store.dispatch('GetAllPermissions');
            } else {
              this.callBackFunction()
            }
          }
        }
      }
    },
    async deleteItem() {
      let permission_ids = [];
      this.model.permissions.forEach(element => {
        permission_ids.push(element.id);
      })
      await this.$store.dispatch('DeleteItemById', {...this.model, permission_ids: permission_ids});
      this.$router.push("/role")
    }
  }
}
</script>

<style scoped>
::v-deep .v-treeview-node__label {
  flex: none !important;
}
::v-deep a:-webkit-any-link {
  color: #224a7d;
  font-weight: 400;
}
</style>